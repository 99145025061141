// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-book-complete-jsx": () => import("./../../../src/pages/book-complete.jsx" /* webpackChunkName: "component---src-pages-book-complete-jsx" */),
  "component---src-pages-book-jsx": () => import("./../../../src/pages/book.jsx" /* webpackChunkName: "component---src-pages-book-jsx" */),
  "component---src-pages-commercial-jsx": () => import("./../../../src/pages/commercial.jsx" /* webpackChunkName: "component---src-pages-commercial-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-explain-powerflush-jsx": () => import("./../../../src/pages/explain-powerflush.jsx" /* webpackChunkName: "component---src-pages-explain-powerflush-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-knowledge-centre-jsx": () => import("./../../../src/pages/knowledge-centre.jsx" /* webpackChunkName: "component---src-pages-knowledge-centre-jsx" */),
  "component---src-pages-reasons-for-powerflush-jsx": () => import("./../../../src/pages/reasons-for-powerflush.jsx" /* webpackChunkName: "component---src-pages-reasons-for-powerflush-jsx" */),
  "component---src-templates-additional-product-jsx": () => import("./../../../src/templates/additional-product.jsx" /* webpackChunkName: "component---src-templates-additional-product-jsx" */),
  "component---src-templates-knowledge-centre-article-jsx": () => import("./../../../src/templates/knowledge-centre-article.jsx" /* webpackChunkName: "component---src-templates-knowledge-centre-article-jsx" */)
}

